import React from 'react';
import "bootstrap/dist/css/bootstrap.css";
import '../App.css';

const sponsors = () => {
    return (
        <section style={{ backgroundColor: "rgb(33, 29, 33)", height: "100vh", }} className='mainSection'>
            <br></br>
            <p style={{ color: "rgb(255, 251, 242)", fontSize: "40px", fontWeight: "bold", textAlign: "center", }}>Our Sponsors</p>
            <br></br>
            <center>
                {/* <div style={{width: "80vw", height: "1000px",}}>
                <Row xs={1} md={3} className="g-3">
                    <Col>
                        <Card style={{ width: '15rem' }}>
                            <Card.Img variant="top" src={adhyaay_logo} />
                            <Card.Body>
                                <Card.Title>Sponsor Name</Card.Title>
                                <Card.Text>
                                Detail
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '15rem' }}>
                            <Card.Img variant="top" src={adhyaay_logo} />
                            <Card.Body>
                                <Card.Title>Sponsor Name</Card.Title>
                                <Card.Text>
                                Detail
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '15rem' }}>
                            <Card.Img variant="top" src={adhyaay_logo} />
                            <Card.Body>
                                <Card.Title>Sponsor Name</Card.Title>
                                <Card.Text>
                                Detail
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card style={{ width: '15rem' }}>
                            <Card.Img variant="top" src={adhyaay_logo} />
                            <Card.Body>
                                <Card.Title>Sponsor Name</Card.Title>
                                <Card.Text>
                                Detail
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div> */}
                <br /><br /><br /><br />
                <h1>STAY TUNED</h1>
            </center>

        </section>
    )
}

export default sponsors;